<template>
  <b-card
    class="ecommerce-card ecom product-card"
    no-body
    :class="!isBrand ? 'ecom-card-padding' : ''"
  >
    <span
      v-if="product.keyStyles"
      class="text-capitalize table-status key-style"
    >
      {{ product.keyStyles }}
    </span>
    <span
      v-if="PRODUCT_STATUS.CANCELLED.VALUE === product.status"
      class="product-status-tag cancelled"
    >{{ PRODUCT_STATUS.CANCELLED.LABEL }}</span>
    <div class="item-img text-center p-0 pb-0 position-realtive">
      <b-link
        @click="openProductDetail(product)"
      >
        <div class="card-square-image d-block">
          <b-card-img-lazy
            class="bg-gray img img-responsive fullwidth"
            :alt="`${product.name}-${product._id}`"
            fluid-grow
            :src="useEcommerceUi().parseProductImage(product)"
            :class="`img-${product._id}`"
            @load.native="calculateImageRatio(`img-${product._id}`)"
            @error.native="imageLoadError"
          />
          <div class="product-color-wrapper">
            <div
              v-for="productColors in product.colors"
              :key="productColors.color"
              class="product-color-bg"
            >
              <div
                v-if="getColorCode(productColors.colorFamily)"
                v-b-tooltip.hover.left="productColors.color"
                class="product-color"
                :class="classForWhiteColor(productColors.colorFamily)"
                :style="'background:' + getColorCode(productColors.colorFamily)"
              />
            </div>
          </div>
        </div>
        <!-- Product Details -->

        <b-card-body class="p-0">
          <div class="px-50 py-25">
            <div class="product-name item-name d-flex">
              <b-link
                :id="`tooltip_product_name_${product._id}`"
                class="text-left"
                @click="openProductDetail(product)"
                @mouseover="
                  showTooltip(
                    `tooltip_product_name_${product._id}`,
                    product._id
                  )
                "
              >
                {{ product.name }}
              </b-link>
              <b-tooltip
                v-if="product._id === tooltipId"
                class="font-Inter"
                :target="`tooltip_product_name_${product._id}`"
                placement="bottom"
                triggers="hover"
              >
                {{ product.name }}
              </b-tooltip>
            </div>
          </div>
        </b-card-body>
      </b-link>
      <div
        v-if="isRetailer"
        :id="`tooltip_add_to_cart_btn_${product._id}`"
        class="item-options text-center shop-card-options"
      >
        <b-button
          variant="none"
          tag="a"
          class="btn-cart p-0 intro-add-cart button-cart"
          :disabled="inactiveCollection"
          @click="handleCartActionClick({product})"
        >
          <feather-icon
            icon="ShoppingCartIcon"
            class="mr-50"
            size="18"
            :class="product.isInCart && 'cart-icon'"
          />
          <span>{{ product.isInCart ? 'In Cart' : 'Add' }}</span>
        </b-button>
        <b-button
          variant="light"
          class="button-wishlist p-0 intro-add-wishlist"
          :disabled="inactiveCollection"
          @click="$emit('on-click-heart')"
        >
          <feather-icon
            icon="HeartIcon"
            size="18"
            :class="{ 'text-danger': product.isInWishlist }"
          />
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BLink,
  BCardBody,
  BTooltip,
  VBTooltip,
  BCardImgLazy,
  BButton,
} from 'bootstrap-vue'
import { utils, constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { isEllipsisActive } from '@/@core/utils/utils'
import ProductImageMixin from '@/views/mixins/ProductImageMixin.vue'

import { getColorCode, classForWhiteColor } from '@/product-colors'
import { productDetailsRouteName, productDetailsWithWishlistRouteName } from '@/router/routes/apps'
import UserRoleMixinVue from '../../UserRoleMixin.vue'
import { useEcommerceUi } from '../../useEcommerce'

const { formatCurrency } = utils

const { PRODUCT_STATUS } = c

export default {
  components: {
    BCard,
    BLink,
    BCardBody,
    BTooltip,
    BCardImgLazy,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [ProductImageMixin, UserRoleMixinVue],
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    inactiveCollection: {
      type: Boolean,
      default: false,
    },
    wishlistId: {
      type: String,
      default: '',
    },
  },
  emits: ['on-click-heart'],
  data() {
    return {
      formatCurrency,
      PRODUCT_STATUS,
      useEcommerceUi,
      getColorCode,
      classForWhiteColor,
      tooltipId: '',
    }
  },
  methods: {
    openProductDetail(product) {
      let name = productDetailsRouteName
      const params = { id: product._id, collectionId: product.collectionId }
      if (this.wishlistId) {
        name = productDetailsWithWishlistRouteName
        params.wishlistId = this.wishlistId
      }
      const routeData = this.$router.resolve({
        name,
        params,
      })
      window.open(routeData.href, '_blank')
    },
    showTooltip(id, productId) {
      const productNameElement = document.getElementById(id)
      if (productNameElement && isEllipsisActive(productNameElement)) {
        this.tooltipId = productId
      }
    },
  },
  setup() {
    const {
      toggleProductInWishlist,
      handleCartActionClick,
    } = useEcommerceUi()

    return {
      toggleProductInWishlist,
      handleCartActionClick,
    }
  },
}
</script>
