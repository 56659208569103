<template>
  <section class="app-ecommerce-details">
    <!-- loaded product info -->
    <template v-if="!loading">
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="product === undefined"
      >
        <h4 class="alert-heading">
          Error fetching product data
        </h4>
        <div class="alert-body">
          No item found with this item slug. Check
          <b-link
            class="alert-link"
            :to="{ name: 'apps-e-commerce-shop' }"
          >
            Shop
          </b-link>
          for other items.
        </div>
      </b-alert>

      <!-- Content -->
      <b-card
        v-if="product"
        no-body
      >
        <div class="w-100 d-flex">
          <div
            v-if="selectedWishlist"
            class="ml-auto m-1"
          >
            <b-button
              id="select_wishlist_button"
              variant="outline-primary"
              @click="$bvModal.show('switch_wishlist_modal_'+product._id)"
            >
              <div
                class="d-flex"
                :class="{
                  'has-value': !!(selectedWishlist && selectedWishlist.name),
                }"
              >
                <feather-icon
                  icon="HeartIcon"
                  size="16"
                  class="text-danger"
                />
                <span class="ml-1">
                  {{
                    !!selectedWishlist
                      ? `${textTruncate(
                        selectedWishlist.name,
                        true,
                        20,
                        15
                      )}(${selectedWishlist.count || 0})`
                      : 'Select a wishlist'
                  }}
                </span>
              </div>
            </b-button>
            <b-tooltip
              target="select_wishlist_button"
              variant="primary"
              placement="bottomleft"
            >
              {{ selectedWishlist.name }}
            </b-tooltip>
          </div>
        </div>
        <b-card-body>
          <b-row class="my-2">
            <!-- Left: Product Image Container -->
            <b-col
              cols="12"
              md="5"
              class="
                d-flex
                align-items-center
                justify-content-center
                mb-2 mb-md-0
              "
            >
              <div class="d-flex align-items-center justify-content-center">
                <b-carousel
                  id="carousel-interval"
                  ref="carousel_products"
                  class="product-images-carousel"
                  :controls="!isHideCarouselControls"
                  indicators
                  :interval="5000"
                >
                  <b-carousel-slide
                    v-for="(image, index) in parseProductImages(product)"
                    :key="`${image}-${index}`"
                  >
                    <template #img>
                      <video
                        v-if="image && image.includes(productVideoExtension)"
                        width="100%"
                        height="100%"
                        muted
                        controls
                        @mousemove="onHoverVideo"
                        @mouseleave="onHoverVideo"
                      >
                        <source
                          :src="image"
                        >
                        Video is not supported.
                      </video>
                      <b-img
                        v-else
                        :src="image"
                        :alt="`${product.name} Gallery`"
                        class="product-img"
                        fluid
                      />
                    </template>
                  </b-carousel-slide>
                </b-carousel>
              </div>
            </b-col>

            <!-- Right: Product Details -->
            <b-col
              cols="12"
              md="7"
            >
              <!-- Product Name -->
              <div class="product-heading-div">
                <h1>{{ product.name }}</h1>
                <b-card-text
                  v-if="isBrand"
                  class="collectionMore"
                  @click="(e)=>{e.preventDefault();showMoreBox()}"
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                  />
                </b-card-text>
                <b-card
                  v-show="showActionsBox"
                  class="actionsDiv"
                >
                  <div class="infoText">
                    <span>Product's Actions</span>
                  </div>
                  <div
                    class="actionItem"
                    @click="showProductWarningModal"
                  >
                    <b-card-text>
                      <span> Delete Product </span>
                    </b-card-text>
                  </div>
                  <div
                    class="actionItem"
                    @click="copyProductLink()"
                  >
                    <b-card-text>
                      <span> Copy Product's Link </span>
                    </b-card-text>
                  </div>
                </b-card>
              </div>
              <!-- Department -->
              <b-card-text class="ml-n1">
                <span class="d-inline-block">
                  <b-breadcrumb
                    class="breadcrumb-products mt-1"
                    :items="[
                      product.department,
                      product.category,
                      product.subCategory,
                    ]"
                  />
                </span>
              </b-card-text>
              <!-- Product style code -->
              <b-card-text class="item-company mb-0">
                <span>Style Code: {{ product.styleCode }}</span>
              </b-card-text>
              <ul class="product-features list-unstyled">
                <div class="row">
                  <div class="col-6">
                    <!-- Pricing -->
                    <li class="product-details-li">
                      <span>
                        Retail:
                        <span class="font-weight-normal">{{
                          formatCurrency(product.retailPrice, product.currency)
                        }}</span>
                      </span>
                    </li>
                    <li
                      v-if="!isGuest && isShowWsp"
                      class="mt-n1"
                    >
                      <span>
                        Wholesale:
                        <span
                          v-if="!product.discountedPrice"
                          class="font-weight-normal"
                          :class="{
                            'text-line-through': product.discountedPrice,
                          }"
                        >
                          {{ formatCurrency(product.price, product.currency) }} </span>
                        <span
                          v-if="product.discountedPrice"
                          class="discounted-price font-weight-bold"
                        >
                          {{
                            formatCurrency(product.discountedPrice, product.currency)
                          }}
                        </span>
                      </span>
                    </li>
                  </div>
                  <div
                    v-if="productDetails.availableCurrencies"
                    class="col-6"
                  >
                    <currency-dropdown
                      :base-currency="collectionBaseCurrency"
                      :selected-currency="selectedCurrency"
                      :currencies="productDetails.availableCurrencies || []"
                      @on-selected-currency="filterByCurrency"
                    />
                  </div>
                </div>
                <hr>
                <li class="product-details-li">
                  <span>
                    Description:
                    <span
                      class="font-weight-normal"
                      v-html="replaceURLTextWithHyperLink(product.descriptionLong)"
                    />
                  </span>
                </li>
                <li class="product-details-li">
                  <span>
                    Material:
                    <span class="font-weight-normal">{{
                      product.descriptionMaterials
                    }}</span>
                  </span>
                </li>
                <li class="product-details-li">
                  <span>
                    Country of Origin:
                    <span class="font-weight-normal">{{
                      product.country
                    }}</span>
                  </span>
                </li>
                <!-- Gender -->
                <li class="product-details-li">
                  <span>
                    Gender:
                    <span class="font-weight-normal">{{ product.gender }}</span>
                  </span>
                </li>
                <li class="product-details-li">
                  <span
                    v-if="product.shippingStartDate && product.shippingEndDate"
                  >
                    Availability Date:
                    <span class="font-weight-normal">{{
                      formattedDate(product.shippingStartDate)
                    }}</span>
                    <span class="font-weight-normal">
                      to {{ formattedDate(product.shippingEndDate) }}</span>
                  </span>
                </li>
              </ul>

              <hr>

              <div
                v-if="product.isCarton && !isGuest"
                class="mb-2 d-flex align-items-center"
              >
                <label class="font-small-4">Quantity:</label>
                <b-form-spinbutton
                  v-model="quantity"
                  class="ml-1"
                  min="1"
                  :max="getMaxQuantity(product.quantityAvailable)"
                  inline
                  :disabled="isBrand || inactiveCollection || isProductValueDisabled(product.quantityAvailable)"
                />

                <div
                  v-if="hasProductMaxQuantity(product.quantityAvailable)"
                  class="text-nowrap color-actions-text-grey max-qty-position mt-2-px"
                >
                  Max Qty: {{ product.quantityAvailable }} </div>
              </div>
              <!-- Variants -->
              <product-variants
                :items="product.items"
                :is-edit-enabled="true"
                :is-carton="product.isCarton"
                :disabled-product="inactiveCollection || isBrand"
                @update-variants="updateVariants"
              />

              <p
                class="text-primary"
                :class="{'text-danger': isNotValidMinQty(product, lineTotalQuantity)}"
              >
                {{ minQtyErrorMessage(product) }}
              </p>

              <template v-if="isRetailer && isShowWsp">
                <hr>
                <div class="d-flex flex-column flex-sm-row pt-1">
                  <div :id="`tooltip_add_to_cart_btn_${product._id}`">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                      :disabled="inactiveCollection || isBrandNotAccessible || isNotValidMinQty(product, lineTotalQuantity)"
                      @click="reactHandleCartActionClick(product)"
                    >
                      <feather-icon
                        icon="ShoppingCartIcon"
                        class="mr-50"
                      />
                      <span>{{
                        product.isInCart ? 'View In Cart' : 'Add to Cart'
                      }}</span>
                    </b-button>
                  </div>
                  <div>
                    <b-button
                      variant="outline-secondary"
                      class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                      :disabled="inactiveCollection || isBrandNotAccessible"
                      @click="createOrUpdateWishlist(product, 'create_wishlist_modal_'+product._id)"
                    >
                      <feather-icon
                        icon="HeartIcon"
                        class="mr-50"
                        :class="{ 'text-danger': product.isInWishlist }"
                      />
                      <span>Wishlist</span>
                    </b-button>
                  </div>
                  <TooltipAddToCartBtn
                    v-if="isBrandNotAccessible && isShowWsp"
                    :target-id="`tooltip_add_to_cart_btn_${product._id}`"
                  />
                </div>
              </template>
            </b-col>
          </b-row>
          <div>
            <div
              v-if="suggestedProducts.length"
              class="ecommerce-application heading-border pt-1"
            >
              <b-card-text class="related-products-title mb-50">
                Recommended for you</b-card-text>
              <section
                ref="cardColumns"
                class="grid-view related-items"
              >
                <template v-for="product of suggestedProducts">
                  <related-product-card
                    :key="product._id"
                    :wishlist-id="wishlistId"
                    :product="product"
                    :inactive-collection="inactiveCollection"
                    @on-click-heart="createOrUpdateWishlist(product, 'create_wishlist_modal_recommended'+product._id)"
                  />

                  <wishlist-list
                    :key="`create_wishlist_modal_key${product._id}`"
                    :modal-id="'create_wishlist_modal_recommended'+product._id"
                    :is-disable-create-wishlist="false"
                    @move-to-wishlist="reactToggleProductInWishlist({...$event, product})"
                  />
                </template>
              </section>
            </div>
            <!-- Infinite Scroll Loading -->
            <section
              v-if="continueLoading"
              class="mt-4"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="text-center"
                >
                  <b-spinner
                    ref="loadingIcon"
                    class="loading-icon"
                    label="Loading..."
                  />
                </b-col>
              </b-row>
            </section>
          </div>
        </b-card-body>

      </b-card>
      <b-modal
        id="modal-select"
        ref="productWarning"
        size="lg"
        modal-class="modal-primary custom-model"
        centered
        hide-header-close
        hide-header
        title="Confirmation Step"
        hide-footer
      >
        <div>
          <div class="pb-1 border-bottom">
            <span class="custom-modal-heading">Confirmation Step </span>
            <feather-icon
              class="float-right close-x-icon"
              icon="XIcon"
              size="24"
              @click="closeProductWarningModal"
            />
          </div>
          <p
            class="mt-1 text-center product-message"
            v-html="productRemoveMessage"
          />
        </div>
        <div>
          <div class="text-center mt-1">
            <b-button
              v-if="canDeleteProduct"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              class="mt-2 mr-2 px-sm-3 font-weight-bolder"
              variant="outline-info"
              @click="closeProductWarningModal"
            >
              Cancel
            </b-button>
            <b-button
              v-show="canDeleteProduct"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="info"
              class="mt-2  px-sm-3 font-weight-bolder"
              :disabled="isSaving"
              @click="removeProduct(true)"
            >
              {{ isSaving ? 'Removing Product...' : 'Yes, remove the product' }}
            </b-button>
            <b-button
              v-show="!canDeleteProduct"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="reset"
              variant="info"
              class="mt-2  px-sm-3 font-weight-bolder"
              :disabled="isSaving"
              @click="closeProductWarningModal"
            >
              Ok! I understand
            </b-button>
          </div>
        </div>
      </b-modal>

      <wishlist-list
        v-if="!!product"
        :modal-id="'create_wishlist_modal_'+product._id"
        :is-disable-create-wishlist="false"
        @move-to-wishlist="reactToggleProductInWishlist({...$event, product})"
      />

      <wishlist-list
        v-if="!!product"
        :modal-id="'switch_wishlist_modal_'+product._id"
        :wishlists="wishlists"
        :selected-wishlist="selectedWishlist"
        :is-disable-create-wishlist="true"
        @move-to-wishlist="switchWishlist($event)"
      />
    </template>
    <!-- loading -->
    <template v-else>
      <div class="text-center p-5 m-5">
        <b-spinner />
      </div>
    </template>
  </section>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import analytics from '@/@core/utils/analytics'
import { apiToastError, apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import { convertToHtml, replaceURLTextWithHyperLink } from '@/common-utils'
import constants, { PRODUCTS_LIMIT, wishlistAction } from '@/constants'
import store from '@/store'
import { DELETE_COLLECTION_PRODUCT, FETCH_COLLECTION_PRODUCT_BY_ID, GET_RELATED_PRODUCTS } from '@/store/modules/collection.module'
import { SET_SELECTION_NAME, FETCH_WISHLIST_PRODUCTS } from '@/store/modules/shop.module'
import { useEcommerce, useEcommerceUi } from '@/views/apps/useEcommerce'
import {
  elementInViewport,
formatObject, getMaxQuantity,
getWishlistTitle,
hasProductMaxQuantity,
isProductValueDisabled,
textTruncate,
} from '@core/utils/utils'
import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'

import {
 ref, onMounted, onUnmounted, watch, computed,
} from '@vue/composition-api'
import {
BAlert,
BBreadcrumb,
BButton,
BCard,
BCardBody,
BCardText,
BCarousel,
BCarouselSlide,
BCol,
BFormSpinbutton,
BImg,
BLink,
BRow,
BSpinner,
VBTooltip,
BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { productDetailsWithWishlistRouteName } from '@/router/routes/apps'
import CurrencyDropdown from '@/views/components/currency-dropdown/CurrencyDropdown.vue'
import UserRoleMixinVue from '../../UserRoleMixin.vue'
import TooltipAddToCartBtn from '../../shop/TooltipAddToCartBtn.vue'
import ProductVariants from './ProductVariants.vue'
import RelatedProductCard from '../other/RelatedProductCard.vue'
import { isNotValidMinQty, minQtyErrorMessage, calculateSizeTotalValues } from '../../checkout/cart-products-utils'
import WishlistList from '../../wishlist/WishlistList.vue'

const { formatCurrency, formattedDate } = utils

const { ROLES } = c

const { CONNECTION_REQUEST_STATUS } = constants
export default {
  name: 'ProductDetails',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BAlert,
    BBreadcrumb,
    BFormSpinbutton,
    BSpinner,
    BCarousel,
    BCarouselSlide,
    BTooltip,
    // SFC
    ProductVariants,
    FeatherIcon,
    TooltipAddToCartBtn,
    RelatedProductCard,
    WishlistList,
    CurrencyDropdown,
  },
  mixins: [UserRoleMixinVue],
  data() {
    return {
    showActionsBox: false,
    isSaving: false,
    canDeleteProduct: false,
    productRemoveMessage: '',
    getMaxQuantity,
    isProductValueDisabled,
    hasProductMaxQuantity,
    replaceURLTextWithHyperLink,
    productVideoExtension: '.mp4',
    isHideCarouselControls: false,
    isNotValidMinQty,
    minQtyErrorMessage,
    textTruncate,
  }
  },
  computed: {
    isShowWsp() {
      return !(this.isWspHidden && this.requestStatus !== CONNECTION_REQUEST_STATUS.APPROVED)
    },
  },
  destroyed() {
    analytics.track(this.isBrand ? constants.TRACKS.ACTIONS.BRAND_END_VIEWING_PRODUCT : constants.TRACKS.ACTIONS.RETAILER_END_VIEWING_PRODUCT, formatObject(this.productDetails))
  },
  methods: {
    switchWishlist(data) {
      this.selectedWishlist = data?.wishlist
      this.wishlistId = data?.wishlistId
      this.$router.push({
          name: productDetailsWithWishlistRouteName,
          params: { id: this.product._id, collectionId: this.product.collectionId, wishlistId: this.wishlistId },
      })
      this.loadProductDetails(true)
    },
    async createOrUpdateWishlist(product = {}, modalId = '') {
      if (this.wishlistId) {
        const payload = {
            wishlistId: this.wishlistId,
            product,
          }
        if (product?.isInWishlist) {
          payload.action = wishlistAction.REMOVE_FROM_WISHLIST
        } else {
          payload.action = wishlistAction.ADD_TO_WISHLIST
        }
        await this.reactToggleProductInWishlist(payload)
        return
      }
      this.$bvModal.show(modalId)
    },
    onHoverVideo(event) {
      if (event?.target?.paused) {
        this.isHideCarouselControls = false
        this.$refs.carousel_products.start()
      } else {
        this.isHideCarouselControls = true
        this.$refs.carousel_products.pause()
      }
    },
    showMoreBox() {
      this.showActionsBox = !this.showActionsBox
    },
    closeProductWarningModal() {
      this.$refs.productWarning.hide()
    },
    async showProductWarningModal() {
      this.showActionsBox = false
      await this.removeProduct()
    },
    async removeProduct(shouldProceed = false) {
      const params = {}
      if (shouldProceed) {
        params.shouldProceed = true
      }
      this.isSaving = true
      if (this.product._id) {
          this.$store.dispatch(DELETE_COLLECTION_PRODUCT, { productId: this.product._id, params })
          .then(res => {
            apiToastSuccess(res.data.message)
            this.isSaving = false
            this.showActionsBox = false
            this.closeProductWarningModal()
            this.$router.push({ path: `/products/${this.$router.history.current.params.collectionId}` })
          })
          .catch(err => {
            const { response } = err
            const { data } = response || {}
            const canDelete = data?.data
            const { message } = data
            this.productRemoveMessage = convertToHtml(message)
            if (canDelete?.shouldProceed) {
              this.canDeleteProduct = true
            } else {
              this.canDeleteProduct = false
            }
            this.$refs.productWarning.show()
            this.isSaving = false
          })
      }
      else {
        this.isSaving = false
        apiToastWarning('Product Not Valid')
      }
    },
    copyProductLink() {
      navigator.clipboard.writeText(window.location.href).then(() => {
        apiToastSuccess('Product link copied')
        this.showActionsBox = false
      }).catch(err => {
        apiToastWarning(err)
      })
    },
  },
  setup(props, { root }) {
    const {
      toggleProductInWishlist,
      handleCartActionClick,
      parseProductImages,
    } = useEcommerceUi()
    const { addProductInWishlist } = useEcommerce()

    const wishlists = ref([])
    const selectedWishlist = ref(null)
    const wishlistId = ref(root.$route.params.wishlistId || '')
    const selectedCurrency = ref('')
    const retailerId = ref(root.$route?.query?.retailerId || '')

    const fetchWishlists = async () => {
      await store.dispatch(FETCH_WISHLIST_PRODUCTS).then(res => {
        if (res?.data?.data?.length) {
          wishlists.value = res.data.data.filter(wishlist => !wishlist.isReadOnly)
          if (!wishlistId.value && wishlists.value.length) {
            selectedWishlist.value = wishlists.value[0]
            wishlistId.value = selectedWishlist.value._id
          }
          if (wishlistId.value) {
            selectedWishlist.value = wishlists.value.find(wishlist => wishlist._id === wishlistId.value)
          }
        }
      })
    }

    const reactToggleProductInWishlist = async (payload = {}) => {
      const result = await toggleProductInWishlist(payload)
      if (result) {
        product.value = result
        analytics.track(constants.TRACKS.ACTIONS.RETAILER_ADDS_TO_WISHLIST_FROM_PP, formatObject({ collectionName: productDetails.value?.collectionName, createdBy: ROLES.RETAILER }))
        loadProductDetails(true)
      }
    }

    const reactHandleCartActionClick = async prod => {
      const result = await handleCartActionClick({
        product: prod,
        quantity: quantity.value,
        items: variants.value,
      })
      if (result) {
        product.value = result
        analytics.track(constants.TRACKS.ACTIONS.RETAILER_ADDS_TO_CART_FROM_PP, formatObject({ collectionName: productDetails.value?.collectionName }))
      }
    }

    const product = ref(null)
    const suggestedProducts = ref([])
    const productsCount = ref(0)
    const continueLoading = ref(false)
    const page = ref(0)
    const isSuggestedProductsLoading = ref(false)
    const loadingIcon = ref(null)

    const isWspHidden = ref(false)
    const isBrandNotAccessible = ref(false)
    const requestStatus = ref(null)
    const inactiveCollection = ref(false)
    const productDetails = ref({})
    const loading = ref(false)
    const colorKey = 'color'
    const isBrand = computed(() => root.$store.getters.entityType === ROLES.BRAND)
    const collectionBaseCurrency = ref('')

    // Remote Data
  const updateProductDetails = response => {
  const data = response.data.data
  productDetails.value = data

  setFlags(data)
  setProduct(data.products[0])
  trackAnalytics(data.products[0])

  if (product.value.isCarton) {
    quantity.value = 1
  }

  lineTotalQuantity.value = quantity.value * calculateSizeTotalValues(product.value.items)
}

const setFlags = data => {
  if (data.hasOwnProperty('isCollectionExpired')) {
    inactiveCollection.value = data.isCollectionExpired
  }

  isWspHidden.value = data.hasOwnProperty('isWspHidden') && data.isWspHidden
  requestStatus.value = data.hasOwnProperty('connectionRequestStatus') ? data.connectionRequestStatus : null
  isBrandNotAccessible.value = data.hasOwnProperty('isBrandNotAccessible') && data.isBrandNotAccessible
}

const setProduct = thisProduct => {
  initializeProductFlags(thisProduct)

  product.value = thisProduct
  if (!selectedCurrency.value) {
    collectionBaseCurrency.value = product.value.currency
  }
}

const trackAnalytics = thisProduct => {
  analytics.track(
    constants.TRACKS.PAGES.PRODUCT_DETAILS,
    formatObject(thisProduct),
  )

  const trackAction = isBrand.value ? constants.TRACKS.ACTIONS.BRAND_START_VIEWING_PRODUCT : constants.TRACKS.ACTIONS.RETAILER_START_VIEWING_PRODUCT
  analytics.track(trackAction, formatObject(productDetails.value))
}

    const initializeProductFlags = thisProduct => {
      if (!thisProduct.hasOwnProperty('isInWishlist')) {
        thisProduct.isInWishlist = false
      }

      if (!thisProduct.hasOwnProperty('isInCart')) {
        thisProduct.isInCart = false
      }
    }

    const updateBreadcrumb = response => {
      root.$route.meta.breadcrumb = root.$route.meta.breadcrumb.map(item => {
        if (item.dynamic === true) {
          if (item.hasOwnProperty('brandName')) {
            item.brandName = response.data.data.brandName
            item.to.params.brandId = response.data.data.brandId
          } else if (item.hasOwnProperty('collectionName')) {
            item.collectionName = response.data.data.collectionName
          } else if (item.hasOwnProperty('productName')) {
            item.productName = product.value.name
          }
        }
        return item
      })
    }

    const handleFetchError = error => {
      loading.value = false
      if (error.response.status === 404) {
        product.value = undefined
      } else {
        apiToastWarning(error)
      }
    }

    const fetchProduct = (isLiveUpdate = false) => {
      loading.value = !isLiveUpdate
      const productId = root.$route.params.id
      const params = {}
      if (wishlistId.value) {
        params.wishlistId = wishlistId.value
      }

      if (selectedCurrency.value) {
        params.currency = selectedCurrency.value
      }

      if (retailerId.value) {
        params.retailerId = retailerId.value
      }
      store
        .dispatch(FETCH_COLLECTION_PRODUCT_BY_ID, { productId, params })
        .then(response => {
          updateProductDetails(response)
          updateBreadcrumb(response)
          loading.value = false
        })
        .catch(handleFetchError)
    }

    const fetchSuggestedStyles = (isLiveUpdate = false) => {
      isSuggestedProductsLoading.value = !isLiveUpdate
        const productId = root.$route.params.id
        const params = {
          page: page.value,
          limit: PRODUCTS_LIMIT,
        }
        if (wishlistId.value) {
          params.wishlistId = wishlistId.value
        }
        store.dispatch(GET_RELATED_PRODUCTS, { productId, params })
        .then(res => {
          const products = res?.data?.data?.products || []
          const count = res?.data?.data?.count || 0
          if (params.page === 0) {
            productsCount.value = 0
          }
          products.forEach(product => {
            const productColors = []
            const colors = []
            product.quantity = product.quantity || 1
              product.items.forEach(item => {
              if (colors.indexOf(item.color) < 0) {
                colors.push(item.color)
                productColors.push({ color: item.color, colorFamily: item.colorFamily })
              }
            })
            product.colors = productColors
          })
          productsCount.value += products.length
          if (count > productsCount.value) {
            continueLoading.value = true
          }
          else {
            continueLoading.value = false
          }
          // append on loading by scroll
          if (params.page >= 1) {
            suggestedProducts.value = [
              ...suggestedProducts.value,
              ...products,
            ]
          }
          // show products to UI
          else {
            suggestedProducts.value = products
          }
          isSuggestedProductsLoading.value = false
        })
        .catch(() => {
          isSuggestedProductsLoading.value = false
        })
      }

    // UI
    const selectedColor = ref(null)

    const loadProductDetails = async (isLiveUpdate = false) => {
      if (!isBrand.value) {
        await fetchWishlists()
      }
      await fetchProduct(isLiveUpdate)
      await fetchSuggestedStyles(isLiveUpdate)
    }

    const filterByCurrency = (currency = '') => {
      selectedCurrency.value = currency || ''
      loadProductDetails(true)
    }

    // Init product details
    loadProductDetails()

    const onScrollLoader = () => {
      if (!isSuggestedProductsLoading.value && elementInViewport(loadingIcon.value)) {
        page.value++
        fetchSuggestedStyles()
      }
    }

    onMounted(() => {
      window.addEventListener('scroll', onScrollLoader)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', onScrollLoader)
    })

    const getProductItems = items => {
      const productItems = []
      items.forEach(item => {
            for (const k in item) {
              if (item.hasOwnProperty(k) && k !== colorKey) {
                productItems.push({
                  sku: item[k].skuId,
                  value: item[k].value,
                })
              }
            }
          })
      return productItems
    }

    const lineTotalQuantity = ref(1)

    // variant
    const variants = ref([])
    const updateVariants = vas => {
      variants.value = vas
      const updatedItems = getProductItems(vas)
      product.value.items = updatedItems
      lineTotalQuantity.value = calculateSizeTotalValues(updatedItems)
    }

    const quantity = ref(1)

    watch(quantity, val => {
      lineTotalQuantity.value = val * calculateSizeTotalValues(product.value.items)
    })

    const updateWishlist = () => {
      if (quantity.value === 0) {
        apiToastError('Please enter quantity')
        return
      }
      let valueEntered = false
      const _items = []
      product.value.items.forEach((item, itemKey) => {
        let _values = []
        variants.value.forEach((variant, k) => {
          if (k === itemKey) {
            _values = Object.values(variant).filter(v => !isNaN(parseInt(v)))
            const zeroed = _values.filter(v => v === 0)
            if (zeroed.length !== _values.length) valueEntered = true
          }
        })
        _items.push({
          itemId: item._id,
          values: _values,
        })
      })
      if (valueEntered) {
        // toggleProductInWishlist(product.value, _items)
        const productPayload = {
          productId: product.value._id,
          quantity: quantity.value,
        }
        if (!product.value.isCarton) {
          productPayload.items = _items
        }

        addProductInWishlist({
          collectionId: product.value.collectionId,
          products: [productPayload],
        })
          .then(res => {
            apiToastSuccess(res.data.message || `Added product to ${getWishlistTitle(this.isRetailer)}!`)
            this.$store.commit(SET_SELECTION_NAME, '')
          })
          .catch(err => {
            apiToastWarning(err)
          })
      } else {
        apiToastError('Please select an item')
      }
    }

    return {
      // Fetched Product
      loading,
      product,
      quantity,
      variants,
      lineTotalQuantity,
      wishlistId,
      wishlists,
      selectedWishlist,
      productDetails,
      retailerId,
      selectedCurrency,

      // UI
      selectedColor,
      toggleProductInWishlist,
      handleCartActionClick,
      updateWishlist,
      parseProductImages,
      updateVariants,
      reactToggleProductInWishlist,
      reactHandleCartActionClick,
      formattedDate,
      formatCurrency,
      fetchProduct,
      fetchSuggestedStyles,
      loadProductDetails,
      isWspHidden,
      isBrandNotAccessible,
      requestStatus,
      inactiveCollection,
      suggestedProducts,
      continueLoading,
      loadingIcon,
      filterByCurrency,
      collectionBaseCurrency,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
@import '~@core/scss/base/pages/app-shop.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
$carousel-arrow-hover-background: #00000032;

.max-qty-position {
  margin-left: 88px;
}

.product-images-carousel {
  &:hover {
    .carousel-control-prev, .carousel-control-next {
      background: $carousel-arrow-hover-background;
  }
  }
}
</style>
