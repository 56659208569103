<template>
  <b-modal
    :id="modalId"
    centered
    hide-header
    hide-footer
    @hidden="resetProperties"
  >
    <div v-if="!isEditSelectionName && !!wishlists.length">
      <div
        class="
          pb-5-px
          border-bottom
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <div class="elements-center">
          <span class="custom-modal-heading"> My {{ getLabelText }}s </span>
        </div>
        <feather-icon
          class="float-right color-neutral-black cursor-pointer"
          icon="XIcon"
          size="24"
          @click="$bvModal.hide(modalId)"
        />
      </div>
      <div class="position-relative col-md-12 p-2">
        <b-form-input
          v-model="search"
          :placeholder="'Search ' + getLabelText"
          class="search-product"
        />
        <feather-icon
          icon="SearchIcon"
          size="18"
          class="wallet-search-icon"
        />
      </div>
      <div class="wishlist-list">
        <div
          v-for="wishlist in filteredWishlists"
          :key="wishlist._id"
          class="item"
          :class="{'bg-secondary text-white': selectedWishlist && selectedWishlist._id === wishlist._id}"
          @click="onSelectWishlist(wishlist)"
        >
          <div class="cart-item-title pl-1">
            {{ wishlist.name }}
          </div>
        </div>
        <div
          v-if="!filteredWishlists.length"
          class="text-muted p-2"
        >
          Wishlists not found
        </div>
      </div>
      <div
        v-if="!isDisableCreateWishlist"
        class="my-75"
      >
        <b-button
          variant="success"
          block
          @click="isEditSelectionName=true"
        >
          Add new {{ getLabelText.toLowerCase() }}
        </b-button>
      </div>
    </div>
    <div v-else>
      <div
        class="
          pb-5-px
          border-bottom
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <div class="elements-center">
          <span class="custom-modal-heading"> Enter {{ getLabelText }} Name </span>
        </div>
        <feather-icon
          class="float-right color-neutral-black cursor-pointer"
          icon="XIcon"
          size="24"
          @click="closeModal()"
        />
      </div>
      <div class="wishlist-list">
        <b-form-group
          class="mt-2"
        >
          <template #label>
            {{ getLabelText }} name
          </template>
          <b-form-input
            v-model="selectionName"
            :placeholder="`Enter ${getLabelText.toLowerCase()} name (optional)`"
          />
        </b-form-group>
      </div>
      <div class="my-75">
        <b-button
          variant="success"
          block
          @click="createSelectionWithName"
        >
          Create
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BButton, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import UserRoleMixin from '@/views/apps/UserRoleMixin.vue'
import { apiToastSuccess } from '@core/utils/toast'

export default {
  components: {
    BModal,
    FeatherIcon,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [UserRoleMixin],
  props: {
    wishlists: {
      type: Array,
      default() {
        return []
      },
    },
    selectedWishlist: {
      type: Object,
      default: () => {},
    },
    isDisableCreateWishlist: {
      type: Boolean,
      default: false,
    },
    modalId: {
      type: String,
      default: 'wishlist-list',
    },
  },
  data() {
    return {
      isEditSelectionName: false,
      selectionName: '',
      selectedWishlistData: {},
      search: '',
    }
  },
  emits: ['move-to-wishlist'],
  computed: {
    getLabelText() {
      return this.isRetailer ? 'Wishlist' : 'Selection'
    },
    filteredWishlists() {
      const searchTerm = this.search?.trim().toLowerCase()
      return this.wishlists.filter(wishlist => wishlist.name?.toLowerCase().includes(searchTerm))
    },
  },
  methods: {
    onSelectWishlist(wishlist = {}) {
      this.selectedWishlistData = wishlist
      this.closeModal()
      this.$emit('move-to-wishlist', { wishlistId: wishlist?._id || null, wishlist: this.selectedWishlistData })
      if (this.isDisableCreateWishlist) {
        apiToastSuccess(`${this.getLabelText} "${wishlist?.name}" selected`)
      } else {
        apiToastSuccess(`Products added to the ${this.getLabelText?.toLowerCase()} "${wishlist?.name}"`)
      }
    },
    createSelectionWithName() {
      const emitData = { wishlistName: this.selectionName, wishlist: this.selectedWishlistData, isCreateWishlist: true }
      this.closeModal()
      this.$emit('move-to-wishlist', emitData)
    },
    closeModal() {
      this.resetProperties()
      this.$bvModal.hide(this.modalId)
    },
    resetProperties() {
      this.isEditSelectionName = false
      this.selectionName = ''
      this.search = ''
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/base/bootstrap-extended/_variables.scss';
.wishlist-list {
  max-height: 500px;
  overflow-y: auto;
  .item {
    padding: 0.9rem 0;
    border: none;
    border-bottom: 1px solid $border-color;
    cursor: pointer;
    &:hover {
      background: $body-bg;
    }
  }
}
.search-product {
  width: 100%;
  padding-right: 2.5rem;
  box-sizing: border-box;
}
.wallet-search-icon {
  position: absolute;
  top: 50%;
  right: 2.2rem;
  transform: translateY(-50%);
  pointer-events: none;
}
.text-muted {
  color: #6c757d;
}
</style>
